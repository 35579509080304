import React from 'react'
import Img1 from '../../images/svg-4.svg';
import Img2 from '../../images/wagepay.png';
import Img3 from '../../images/svg-6.svg';
import Img4 from '../../images/capitalhubfinancialwellness.png';
import Img5 from '../../images/capitalhubtransfer.png';
import Img6 from '../../images/API.gif';
import Img7 from '../../images/partnerships.png';
import Img9 from '../../images/capitalhubwallet.jpg';

import {
    ServicesContainer, 
    ServicesH1, 
    ServicesWrapper, 
    ServicesCard, 
    ServicesIcon, 
    ServicesH2, 
    ServicesP 
} from './ServicesStyle';

const Services = (props) => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Img2}/>
                    <ServicesH2>Earned Wage Access</ServicesH2>
                    <ServicesP>Employees can access a portion of their earned wages before payday</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Img9} />
                    <ServicesH2>Employee Wallet</ServicesH2>
                    <ServicesP>A digital wallet where employees receive wage advances, and make transfers.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Img5} />
                    <ServicesH2>Instant Transfers</ServicesH2>
                    <ServicesP>Employees can transfer funds from their CapitalHub wallet to external accounts via instant transfers.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Img4} />
                    <ServicesH2>Financial Wellness </ServicesH2>
                    <ServicesP>Educational videos designed to help employees develop better financial habits</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Img6} />
                    <ServicesH2>Employer Integration </ServicesH2>
                    <ServicesP>Simple & seamless integrations allowing employers to offer EWA services to their employees.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Img7} />
                    <ServicesH2>CapitalHub Partner Suite </ServicesH2>
                    <ServicesP>Our cost-effective solutions give partner companies access to premium software tools at a fraction of the usual cost.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    );
};

export default Services;
