import styled from 'styled-components';

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};
  padding: 80px 0; // Add padding to top and bottom

  @media screen and (max-width: 768px) {
    padding: 60px 0; // Reduce padding on mobile
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) => ( imgStart ? `'col2 col1'` : `'col1 col2'` )};
  grid-gap: 20px; // Add gap between columns

  @media screen and (max-width: 768px) {
    grid-template-areas: 'col1' 'col2';
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 30px; // Reduce padding on mobile
  }
`;

export const TopLine = styled.p`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: ${({ isEmployerSection }) => (isEmployerSection ? '1.3' : '1.1')};
  font-weight: 600;
  color: ${({ lightText }) => ( lightText ? '#f7f8fa' : '#010606' )};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: ${({ isEmployerSection }) => (isEmployerSection ? '1.8' : '1.5')};
  color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};

  @media screen and (max-width: 768px) {
    font-size: 16px; // Slightly reduce font size on mobile
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${({ isEmployerSection }) => (isEmployerSection ? '30px' : '0')};
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;

  @media screen and (max-width: 768px) {
    max-width: 100%; // Allow image to take full width on mobile
    height: auto; // Adjust height automatically
    display: ${({ hideOnMobile }) => hideOnMobile ? 'none !important' : 'block'}; // Hide if hideOnMobile prop is true
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  max-height: 500px; // Limit maximum height
  object-fit: contain; // Ensure the entire image is visible

  @media screen and (max-width: 768px) {
    max-height: 300px; // Reduce maximum height on mobile
  }
`;

