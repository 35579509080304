import React from 'react';
import { Button } from '../ButtonStyle';
import GlowingButton from './GlowingButton';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Column2,
  Img,
} from './InfoStyle';

const Info = (props) => {
  const isEmployerSection = props.id === 'signup'; // Check if it's the "For Employers" section

  return (
    <>
      <InfoContainer lightBg={props.lightBg} id={props.id}>
        <InfoWrapper>
          <InfoRow imgStart={props.imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{props.topLine}</TopLine>
                <Heading lightText={props.lightText} isEmployerSection={isEmployerSection}>
                  {props.headline}
                </Heading>
                <Subtitle darkText={props.darkText} isEmployerSection={isEmployerSection}>
                  {props.description}
                </Subtitle>
                <BtnWrap isEmployerSection={isEmployerSection}>
                  {props.buttonLabel === 'Star on ProductHunt' ? (
                    <GlowingButton url="https://www.producthunt.com/posts/capitalhub">
                      {props.buttonLabel}
                    </GlowingButton>
                  ) : (
                    <Button
                      to='home'
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact='true'
                      offset={-80}
                      primary={props.primary ? 1 : 0}
                      dark={props.dark ? 1 : 0}
                      dark2={props.dark2 ? 1 : 0}
                    >
                      {props.buttonLabel}
                    </Button>
                  )}
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap hideOnMobile={isEmployerSection}>
                <Img src={props.img} alt={props.alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Info;
