import React, {useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { 
    Nav, 
    NavContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks, 
    NavBtn,
    NavBtnLink
} from './NavStyle.js';

const Navbar = ({ toggle }) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    } // => first transparent and after scroll black 

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }


    return (
        <>
        <IconContext.Provider value={{color: '#01BF71'}}>
            <Nav scrollNav={scrollNav}>
                <NavContainer>
                      <NavLogo to="/" onClick={toggleHome}>CapitalHub</NavLogo>
                      <MobileIcon onClick={toggle} >
                          <FaBars />
                      </MobileIcon>
                      <NavMenu>
                          <NavItem>
                              <NavLinks 
                              to='about'
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact='true'
                              offset={-80}
                              > About </NavLinks>
                          </NavItem>
                          <NavItem>
                              <NavLinks 
                              to='discover'
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact='true'
                              offset={-80}
                              > Discover </NavLinks>
                          </NavItem>
                          <NavItem>
                              <NavLinks 
                              to='services'
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact='true'
                              offset={-80} /* -80 because the nav is with -80 of margin */
                              > Solutions </NavLinks>
                          </NavItem>
                          <NavItem>
                              <NavLinks 
                              to='signup'
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact='true'
                              offset={-80}
                              > Partnership </NavLinks>
                          </NavItem>
                      </NavMenu>
                      <NavBtn>
                          <NavBtnLink to='/signin' style={{ color: '#fff' }}>Download App</NavBtnLink>
                      </NavBtn>
                </NavContainer>
            </Nav>

        </IconContext.Provider>
        </>
    )
}

export default Navbar

