import React, { useState, useEffect } from 'react'
import Video from '../../videos/video.mp4';
import { Button } from '../ButtonStyle';
import { 
    HeroContainer, 
    HeroBg, 
    VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight
} from './HeroStyle';

const Hero = (props) => {

    const [hover, setHover] = useState(false);
    const [typedText, setTypedText] = useState('');
    const fullText = "Earned Wage Access Made Simple";
    const [commentIndex, setCommentIndex] = useState(0);

    const onHover = () => {
        setHover(!hover)
    }

    const ewaComments = [
        "Access up to 50% of your earned wages instantly!",
        "0% interest on all advances with CapitalHub",
        "Financial freedom at your fingertips",
        "Say goodbye to payday loans with CapitalHub",
        "Empower yourself with on-demand pay"
    ];

    useEffect(() => {
        if (typedText.length < fullText.length) {
            const timeout = setTimeout(() => {
                setTypedText(fullText.slice(0, typedText.length + 1));
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [typedText]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCommentIndex((prevIndex) => (prevIndex + 1) % ewaComments.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>{typedText}</HeroH1>
                <HeroP>
                    {ewaComments[commentIndex]}
                </HeroP>
                <HeroP>
                    Instantly access up to 50% of your unpaid wages before payday—no interest, no hassle!
                </HeroP>
                <HeroBtnWrapper>
                    <Button 
                    to='signup' 
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover} 
                    primary='true' 
                    dark='true'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                    >
                        Get started {hover ? <ArrowForward /> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default Hero;
