import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Confetti from 'react-confetti';
import ReactDOM from 'react-dom';

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #ff6600; }
  50% { box-shadow: 0 0 20px #ff6600; }
  100% { box-shadow: 0 0 5px #ff6600; }
`;

const StyledButton = styled.button`
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  animation: ${glowAnimation} 2s infinite;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff8533;
  }
`;

const ConfettiWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
`;

const GlowingButton = ({ children, url }) => {
  const [showConfetti, setShowConfetti] = useState(false);

  const handleClick = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
      window.open(url, '_blank');
    }, 2000);
  };

  return (
    <>
      <StyledButton onClick={handleClick}>{children}</StyledButton>
      {showConfetti &&
        ReactDOM.createPortal(
          <ConfettiWrapper>
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={200}
              gravity={0.5}
            />
          </ConfettiWrapper>,
          document.body
        )}
    </>
  );
};

export default GlowingButton;