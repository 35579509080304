import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { BsInfinity } from 'react-icons/bs';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const ButtonContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const FloatingButton = styled.div`
   width: 40px;
   height: 40px;
   font-size: 2rem;
   z-index: 1000;
   cursor: pointer;
   background-color: #01bf71;
   color: white;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: opacity 0.3s, transform 0.3s;
   opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
   transform: ${({ isVisible }) => (isVisible ? 'scale(1)' : 'scale(0)')};
   &:hover {
     background-color: #00a362;
   }
`;

const ScrollButton = styled(FloatingButton)``;

const InfinityButton = styled(FloatingButton)`
  animation: ${pulse} 2s infinite;
`;

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // Trigger a custom event for GTM
    window.dataLayer.push({
      'event': 'button_click',
      'button_name': 'scroll_to_top'
    });
  };

  const goToIGBS = () => {
    window.open('https://igbs.io', '_blank');
    // Trigger a custom event for GTM
    window.dataLayer.push({
      'event': 'button_click',
      'button_name': 'igbs_link'
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <ButtonContainer>
      <InfinityButton 
        isVisible={isVisible} 
        onClick={goToIGBS} 
        aria-label="Visit IGBS website"
        data-gtm-event="button_click"
        data-gtm-button-name="igbs_link"
      >
        <BsInfinity />
      </InfinityButton>
      <ScrollButton 
        isVisible={isVisible} 
        onClick={scrollToTop} 
        aria-label="Scroll to top"
        data-gtm-event="button_click"
        data-gtm-button-name="scroll_to_top"
      >
        <FaArrowCircleUp />
      </ScrollButton>
    </ButtonContainer>
  );
}

export default ScrollToTop;