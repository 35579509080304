import Img1 from '../../images/svg-3.svg';
import Img2 from '../../images/svg-7.svg';
import Img3 from '../../images/svg-2.svg';
import Img8  from '../../images/employer.png';


export const HomeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'CapitalHub',
    headline: 'Mobile App',
    description: 'The CapitalHub App features a user-friendly interface designed to make financial management simple and intuitive. With a clean, modern design and seamless navigation, users can easily access their wages, track transactions, and manage their financial wellness on the go.',
    buttonLabel: 'Star on ProductHunt',
    imgStart: false, 
    img: Img1,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const HomeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'For Employees',
    headline: 'Why You Need CapitalHub',
    description: 'CapitalHub gives you instant access to your earned wages before payday, offering the flexibility to manage unexpected expenses without relying on costly loans. Our user-friendly app allows you to easily track earnings, make secure transfers, and access educational tools to enhance your financial habits.',
    buttonLabel: 'Get the App',
    imgStart: true, 
    img: Img2,
    alt: 'app',
    dark: false,
    primary: false,
    darkText: true
};

export const HomeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'For Employers',
    headline: 'Partner with Us',
    description: 'CapitalHub helps employers boost employee satisfaction and retention by offering instant access to earned wages before payday. This benefit reduces financial stress, increasing productivity and engagement. Our platform integrates easily with your payroll system, simplifying wage advances and deductions, while fostering a supportive workplace culture and attracting top talent.',
    buttonLabel: 'Contact Us',
    imgStart: false, 
    img: Img8,
    alt: 'multiplatform',
    dark: false,
    primary: false,
    darkText: true
};
